import { useIsClassic } from "data/query"
import { Contacts } from "components/layout"
import styles from "./Links.module.scss"

const Links = () => {
  const isClassic = useIsClassic()

  const community = isClassic
    ? {
        discord: "https://terra.sc/classicdiscord",
        telegram: "https://t.me/TerraLunaChat",
        twitter: "https://twitter.com/terrac_money",
      }
    : {
        // medium: "https://medium.com/terra-money",
        discord: "https://discord.gg/fDDHFc7M",
        telegram: "https://t.me/station_wallet",
        twitter: "https://twitter.com/StationWallet",
        github: "https://github.com/StationWallet",
      }

  return (
    <div className={styles.links}>
      <div className={styles.community}>
        <Contacts contacts={community} menu />
      </div>
    </div>
  )
}

export default Links
