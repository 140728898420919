import { useQuery } from "react-query"
import { queryKey, RefetchOptions } from "../query"
import { useLCDClient } from "./lcdClient"

export const useBlockHeight = () => {
  const lcd = useLCDClient()

  return useQuery(
    [queryKey.tendermint.blockInfo],
    async () => {
      const blockInfo = await lcd.tendermint.blockInfo()
      return blockInfo.block.header.height
    },
    { ...RefetchOptions.INFINITY }
  )
}
